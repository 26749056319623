.team-page-container {
  .core {
    display: flex;
    justify-content: center;
    background: #fff;
    padding: 55px 0;

    .core-content {
      width: 100%;
      max-width: 1100px;
      text-align: center;

      .en-title {
        font-size: 24px;
        color: #0E80D7;
        margin-bottom: 18px;
      }

      .cn-title {
        font-size: 56px;
        font-weight: bold;
        color: #1D1D1D;
        margin-bottom: 39px;
      }

      .desc {
        font-size: 20px;
        color: #2C2C2C;
        margin-bottom: 50px;
        text-align: left;

        .title {
          font-size: 30px;
          color: #0E80D7;
          margin-bottom: 20px;
        }

        .text {
          text-indent: 2em;
          line-height: 40px;
        }
      }

      .list {
        display: flex;
        justify-content: space-between;

        .item {
          width: 33.3%;
          flex: 1 1 33.3%;

          .content {
            display: flex;
            justify-content: center;
            align-items: center;
            background: #FFFFFF;
            box-sizing: border-box;
          }
        }
      }
    }
  }

  .group {
    display: flex;
    justify-content: center;
    background: #F6F6F6;
    padding: 55px 0;

    .group-content {
      width: 100%;
      max-width: 1360px;
      text-align: center;

      .en-title {
        font-size: 24px;
        color: #0E80D7;
        margin-bottom: 18px;
      }

      .cn-title {
        font-size: 56px;
        font-weight: bold;
        color: #1D1D1D;
        margin-bottom: 58px;
      }

      .desc {
        font-size: 20px;
        color: #2C2C2C;
        margin-bottom: 50px;
        text-align: left;

        .title {
          font-size: 30px;
          color: #0E80D7;
          margin-bottom: 20px;
        }

        .text {
          text-indent: 2em;
          line-height: 40px;
        }
      }

      .list {
        display: flex;
        justify-content: space-between;

        .content {
          display: flex;
          height: 100%;
          flex-direction: column;
          align-items: center;
          background-color: #fff;
          cursor: pointer;
          transition: all 0.4s;

          &:hover {
            box-shadow: 0px 1px 10px 1px rgba(8, 1, 3, 0.2);
          }

          .icon {
            width: 188px;
            height: 188px;
            padding: 30px;
          }

          .info {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0 10px;

            .avatar {
              width: 100px;
              margin-right: 10px;
            }

            .edu {
              flex: 1;
              font-size: 14px;
              font-weight: bold;
              color: #1C1C1C;
              text-align: left;

              p {
                margin-bottom: 4px;
              }
            }
          }

          .label {
            width: 100%;
            font-size: 13px;
            color: #191919;
            text-align: left;
            padding: 20px 10px;

            p {
              margin-bottom: 4px;
            }
          }
        }
      }
    }
  }

  .adviser {
    display: flex;
    justify-content: center;
    background: #F6F6F6;
    padding: 55px 0;

    .adviser-content {
      width: 100%;
      max-width: 1360px;
      text-align: center;

      .en-title {
        font-size: 24px;
        color: #0E80D7;
        margin-bottom: 18px;
      }

      .cn-title {
        font-size: 56px;
        font-weight: bold;
        color: #1D1D1D;
        margin-bottom: 39px;
      }

      .desc {
        font-size: 20px;
        color: #2C2C2C;
        margin-bottom: 50px;
        text-align: left;

        .title {
          font-size: 30px;
          color: #0E80D7;
          margin-bottom: 20px;
        }

        .text {
          text-indent: 2em;
          line-height: 40px;
        }
      }

      .list {
        display: flex;
        justify-content: space-between;

        .content {
          display: flex;
          height: 100%;
          flex-direction: column;
          align-items: center;
          background-color: #fff;
          cursor: pointer;
          transition: all 0.4s;

          &:hover {
            box-shadow: 0px 1px 10px 1px rgba(8, 1, 3, 0.2);
          }

          .icon {
            position: relative;
            width: 100%;

            .name {
              position: absolute;
              bottom: 4px;
              left: 0;
              padding: 4px 10px;
              color: #fff;
              background-color: #0E80D7;
              font-size: 20px;
            }
          }

          .label {
            width: 100%;
            font-size: 13px;
            color: #191919;
            text-align: left;
            padding: 20px 10px;

            p {
              margin-bottom: 4px;
            }
          }
        }
      }
    }
  }

  .cert {
    display: flex;
    justify-content: center;
    background: #F6F6F6;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 55px 0;

    .cert-content {
      width: 100%;
      max-width: 1360px;
      text-align: center;

      .en-title {
        font-size: 24px;
        color: #0E80D7;
        margin-bottom: 18px;
      }

      .cn-title {
        font-size: 56px;
        font-weight: bold;
        color: #fff;
        margin-bottom: 68px;
      }

      .desc {
        font-size: 20px;
        color: #2C2C2C;
        margin-bottom: 50px;
        text-align: left;

        .title {
          font-size: 30px;
          color: #0E80D7;
          margin-bottom: 20px;
        }

        .text {
          text-indent: 2em;
          line-height: 40px;
        }
      }
    }
  }

  .other {
    display: flex;
    justify-content: center;
    background: #F6F6F6;
    padding: 55px 0;

    .other-content {
      width: 100%;
      max-width: 1360px;

      .en-title {
        font-size: 24px;
        color: #0E80D7;
        margin-bottom: 18px;
        text-align: center;
      }

      .cn-title {
        font-size: 56px;
        font-weight: bold;
        color: #1D1D1D;
        margin-bottom: 79px;
        text-align: center;
      }

      .aaa,
      .iso {
        margin-top: 50px;

        .sub-title {
          font-size: 30px;
          color: #1B1B1B;
          margin-bottom: 12px;
        }

        .list {
          .item {
            .ant-image {
              box-shadow: 3px 5px 5px 0px rgba(8, 1, 3, 0.24);
            }
          }
        }
      }
    }
  }
}