.steel-plate-list-page-container {
  background-color: #fff;

  .list {
    display: flex;
    justify-content: center;

    .list-content {
      width: 1400px;
      padding: 40px;
      cursor: pointer;

      .item {
        cursor: pointer;
        transition: all 0.4s;

        &:hover {
          box-shadow: 0px 1px 10px 1px rgba(8, 1, 3, 0.2);
        }

        .name {
          font-size: 20px;
          color: #333333;
          margin-top: 20px;
          padding: 0 20px;
        }

        .desc {
          font-size: 16px;
          color: #666666;
          margin-top: 20px;
          padding: 0 20px;
        }

        button {
          margin: 10px 0 20px 20px;
        }
      }

    }
  }

  .pagination-content {
    padding-bottom: 20px;
  }
}