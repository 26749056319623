.fab-container {

  >div {
    visibility: hidden;
    opacity: 0;
    transition: all .4s ease;
  }

  .ant-affix {
    visibility: visible;
    opacity: 1;
  }

  .content {
    width: 70px;
    display: flex;
    flex-direction: column;
    background: #34353E;
    border-radius: 23px 0px 0px 23px;
    overflow: hidden;

    .item,
    .item.ant-back-top {
      position: relative;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      width: 70px;
      height: 70px;
      line-height: 14px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      color: #fff;
      font-size: 10px;
      padding: 0 10px;
      text-align: center;
      transition: all 0.3s;
      cursor: pointer;

      &:last-child {
        padding: 0 12px;
      }

      &:hover {
        background-color: #0E80D7;
      }

      img {
        width: 16px;
        margin-bottom: 6px;
      }
    }
  }
}