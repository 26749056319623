.prod-base-page-container {
  .zb-base {
    background-color: #fff;
  }

  .zj-base {
    background-color: #F6F6F6;
  }

  .zb-base,
  .zj-base {
    display: flex;
    flex-direction: column;
    align-items: center;

    .intro-content {
      width: 100%;
      max-width: 1400px;
      display: flex;
      flex-wrap: wrap;
      padding: 104px 0 76px;

      .block {
        flex: 0 0 50%;
        padding: 13px;

        &-1 {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;

          .en {
            font-size: 24px;
            color: #0E80D7;
            margin-bottom: 10px;
          }

          .cn {
            font-size: 50px;
            font-weight: bold;
            color: #1D1D1D;
            margin-bottom: 40px;
          }

          .text {
            font-size: 18px;
            color: #1D1D1D;
            line-height: 36px;
            text-indent: 2em;
          }
        }

        &-4 {
          display: flex;
          justify-content: center;
          align-items: center;

          .text {
            font-size: 18px;
            color: #1D1D1D;
            line-height: 36px;
            text-indent: 2em;
          }
        }
      }
    }

    .base-content {
      width: 100%;
      max-width: 1400px;
      display: flex;

      .img+.img {
        margin-left: 33px;
      }

      .img {
        width: 25%;
        flex: 1 1 25%;
      }
    }

    .addr-content {
      width: 100%;
      max-width: 1400px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 110px 0 140px 0;

      .left {
        width: 480px;
        margin-right: 64px;

        .title {
          font-size: 30px;
          font-weight: bold;
          color: #1D1D1D;
          margin-bottom: 22px;
        }

        .desc {
          line-height: 30px;
          font-size: 20px;
          font-weight: 400;
          color: #1D1D1D;
        }
      }

      .right {
        flex: 1;

        iframe {
          width: 100%;
          height: 600px;
        }
      }
    }
  }
}