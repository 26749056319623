.steel-plate-layout-container {

  .cate-list {
    display: flex;
    justify-content: center;
    padding-top: 80px;

    .cate-content {
      width: 100%;
      max-width: 1400px;

      .item {
        height: 70px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 30px;
        color: #fff;
        background: #0E80D7;
        transition: all 0.4s;
        cursor: pointer;

        &.active,
        &:hover {
          background: #FFFFFF;
          color: #0E80D7;
          box-shadow: 4px 2px 5px 0px rgba(8, 1, 3, 0.3);

          .img {
            &.normal {
              visibility: hidden;
              opacity: 0;
              position: absolute;
            }

            &.selected {
              visibility: visible;
              opacity: 1;
              position: relative;
            }
          }
        }

        .img {
          width: 40px;

          &.selected {
            visibility: hidden;
            opacity: 0;
            position: absolute;
          }
        }

        .name {
          font-size: 28px;
          letter-spacing: 2px;
        }
      }
    }
  }
}