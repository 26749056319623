@import '~antd/dist/antd.css';

img {
  display: block;
  width: 100%;
}

.ant-image-preview-img {
  width: auto;
  display: inline;
}