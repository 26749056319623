.news-detail-page-container {
  display: flex;
  justify-content: center;
  background-color: #fff;

  .content {
    width: 100%;
    max-width: 1200px;
    padding: 90px 0 240px 0;

    .title {
      font-size: 24px;
      font-weight: bold;
      color: #333333;
      text-align: center;
    }

    .info {
      display: flex;
      justify-content: center;
      font-size: 14px;
      padding: 12px 0;
      color: #999999;
      background-color: #F5F5FA;
      margin-top: 34px;
      margin-bottom: 46px;

      .date {
        margin-left: 20px;
      }
    }
  }
}