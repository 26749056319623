.news-list-page-container {
  background-color: #fff;

  .list {
    display: flex;
    justify-content: center;

    .list-content {
      width: 100%;
      max-width: 1200px;
      padding: 40px;

      .item {
        background-color: #fff;
        transition: all 0.4s;
        cursor: pointer;
        padding-top: 20px;
        padding-bottom: 20px;

        &:hover {
          background-color: #f1f1f1;
        }

        .name {
          font-size: 20px;
          color: #333333;
        }

        .desc {
          font-size: 14px;
          color: #666666;
        }

        .day {
          font-size: 40px;
          font-weight: bold;
          color: #222222;
          text-align: center;
        }

        .month {
          font-size: 18px;
          color: #999999;
          text-align: center;
        }
      }
    }
  }

  .pagination-content {
    padding-bottom: 20px;
  }
}