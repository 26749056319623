.about-page-container {
  width: 100%;

  .intro-content {
    width: 100%;
    max-width: 1400px;
    display: flex;
    flex-wrap: wrap;
    margin: 0 auto;
    padding: 104px 0 76px;

    .block {
      flex: 0 0 50%;
      padding: 13px;

      &-1 {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;

        .en {
          font-size: 24px;
          color: #0E80D7;
          margin-bottom: 10px;
        }

        .cn {
          font-size: 50px;
          font-weight: bold;
          color: #1D1D1D;
          margin-bottom: 40px;
        }

        .text {
          font-size: 18px;
          color: #1D1D1D;
          line-height: 36px;
          text-indent: 2em;
        }
      }

      &-4 {
        display: flex;
        justify-content: center;
        align-items: center;

        .text {
          font-size: 18px;
          color: #1D1D1D;
          line-height: 36px;
          text-indent: 2em;
        }
      }
    }
  }

  .core-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 64px 0;
    background-color: #025695;

    .en {
      font-size: 24px;
      color: #FFFFFF;
      margin-bottom: 18px;
    }

    .title {
      font-size: 56px;
      font-weight: bold;
      color: #FFFFFF;
      margin-bottom: 70px;
    }

    .intro {
      font-size: 18px;
      color: #FFFFFF;
      margin-bottom: 46px;
    }

    .num {
      display: flex;

      .item+.item {
        // border-left: 1px solid #fff;

        &::after {
          content: "";
          width: 1px;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          background: linear-gradient(243deg, #025695 0%, #fff 51%, #025695 100%);
        }
      }

      .item {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 40px 60px;

        .text1 {
          font-size: 48px;
          font-weight: 500;
          color: #FF9000;
          margin-right: 12px;
        }

        .text2 {
          font-size: 24px;
          font-weight: 500;
          color: #FFFFFF;
        }
      }
    }
  }

  .history-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
    padding: 100px 0 56px 0;

    .en {
      font-size: 24px;
      color: #0E80D7;
      margin-bottom: 18px;
    }

    .title {
      font-size: 56px;
      font-weight: bold;
      color: #0E80D7;
      margin-bottom: 70px;
    }

    .timeline {
      width: 100%;
      max-width: 1400px;

      .item {
        display: flex;
        align-items: stretch;

        &:hover {
          .info .content {
            box-shadow: 0px 5px 11px 0px rgba(7, 0, 2, 0.35);
          }

          .year {
            .circle {
              color: #fff;
              background: #0E80D7;
            }
          }
        }

        .info {
          position: relative;
          height: auto;

          .content {
            padding: 20px;
            background: #fff;
            transition: all 0.3s ease-in-out;

            .course {
              font-size: 24px;
              font-weight: bold;
              color: #0E80D7;
            }

            .info-title {
              font-size: 20px;
              color: #232323;
              font-weight: bold;
              margin: 22px 0;
            }

            .intro {
              font-size: 18px;
              color: #1E1E1E;
            }

            .imgs {
              display: flex;
              flex-wrap: wrap;
              justify-content: space-between;

              img {
                width: 150px;
                transition: all .3s;

                &:hover {
                  transform: scale(2);
                }
              }
            }
          }
        }

        .year {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;

          .line {
            width: 6px;
            height: 100%;
            position: absolute;
            // top: 50%;
            // left: 50%;
            // transform: translate(-50%, -50%);
            background-color: #B5B4B4;
          }

          .circle {
            position: relative;
            z-index: 1;
            width: 115px;
            height: 115px;
            display: flex;
            justify-content: center;
            align-items: center;
            background: #F3F1F1;
            box-shadow: -1px 0px 7px 0px rgba(8, 1, 3, 0.3);
            border-radius: 50%;
            border: 5px solid #fff;
            border-radius: 100%;
            font-size: 24px;
            color: #0E80D7;
            transition: all 0.3s ease-in-out;
          }
        }
      }
    }
  }

  .culture-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 50px 0;

    .en {
      font-size: 24px;
      color: #0E80D7;
      margin-bottom: 18px;
    }

    .title {
      font-size: 56px;
      font-weight: bold;
      color: #0E80D7;
      margin-bottom: 70px;
    }

    .content {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .left {
        max-width: 580px;
        margin-right: 100px;
      }

      .right {
        .item+.item {
          border-top: 1px solid #C5C3C3;
        }

        .item {
          padding: 24px 0;

          .intro-title {
            font-size: 26px;
            font-weight: bold;
            color: #1B1B1B;
            margin-bottom: 20px;
          }

          p {
            font-size: 18px;
            color: #1B1B1B;
            margin-top: 15px;
          }
        }
      }
    }
  }
}