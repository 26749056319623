// ratio mixin: $ratio = w/h * 100%
@mixin ratio($ratio) {
  position: relative;
  box-sizing: content-box;
  overflow: hidden;
  padding: $ratio 0 0;
  height: 0;

  &>image,
  &>video,
  &>swiper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
