.ant-layout-footer.site-footer {
  width: 100%;

  .top-content {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #03518C;

    .max-content {
      width: 100%;
      max-width: 1200px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 60px 0;

      .left-content {
        .info {
          text-align: left;

          .groupname {
            font-size: 20px;
            color: #fff;
          }

          .tel {
            font-size: 20px;
            color: #fff;
            margin: 60px 0 20px 0;

            a {
              color: #fff;
            }
          }

          .address {
            margin-top: 60px;
            margin-bottom: 6px;
          }

          .address,
          .zipcode {
            font-size: 14px;
            color: #fff;
          }

        }
      }

      .right-content {
        .qrcode {
          width: 138px;
          height: 138px;

          .qrtext {
            font-size: 14px;
            color: #fff;
            margin-top: 10px;
            text-align: center;
          }
        }
      }
    }
  }

  .bottom-content {
    width: 100%;
    line-height: 50px;
    text-align: center;
    border-top: 1px solid #A9A9A9;
    background-color: #03518C;

    a {
      color: #fff;
      font-size: 16px;
    }
  }
}