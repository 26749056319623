.project-detail-page-container {
  display: flex;
  justify-content: center;
  background-color: #fff;

  .content {
    width: 100%;
    max-width: 1200px;
    padding: 90px 0 240px 0;

    .title {
      font-size: 24px;
      font-weight: bold;
      color: #333333;
      text-align: center;
    }

    .date {
      font-size: 14px;
      color: #999999;
      margin-top: 20px;
      margin-bottom: 40px;
      text-align: center;
    }
  }
}