.contact-page-container {
  .contact-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 55px 0;
    background: #F6F6F6;

    .en-title {
      font-size: 24px;
      color: #0E80D7;
      margin-bottom: 30px;
    }

    .cn-title {
      font-size: 56px;
      font-weight: bold;
      color: #1D1D1D;
      margin-bottom: 70px;
    }


    .list {
      width: 100%;
      max-width: 1200px;

      .item {
        .icon {
          width: 88px;
          height: 88px;
        }

        .title {
          font-size: 18px;
          color: #333333;
          margin-top: 20px;
        }

        .text {
          font-size: 18px;
          color: #666666;
          margin-top: 10px;
          text-align: center;
        }
      }
    }
  }

  .company-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 75px 0;
    background: #F6F6F6;

    .en-title {
      font-size: 24px;
      color: #0E80D7;
      margin-bottom: 30px;
    }

    .cn-title {
      font-size: 56px;
      font-weight: bold;
      color: #1D1D1D;
      margin-bottom: 70px;
    }

    .map {
      width: 1200px;
      height: 600px;
    }
  }
}