.steel-plate-home-page-container {
  .intro-content {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 110px 0 140px 0;
    background-color: #fff;

    .left {
      width: 480px;
      margin-right: 64px;

      .en {
        font-size: 24px;
        color: #0E80D7;
        margin-bottom: 4px;
      }

      .title {
        font-size: 32px;
        font-weight: bold;
        color: #1A1A1A;

      }

      .desc {
        font-size: 20px;
        color: #2E2E2E;
        line-height: 40px;
        margin-top: 32px;
        text-indent: 2em;
      }
    }

    .right {
      width: 632px;
      cursor: pointer;
      transform-style: preserve-3d;
      transition: all .1s;
    }
  }

  .adv-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 55px 0;
    background: #F6F6F6;

    .en-title {
      font-size: 24px;
      color: #0E80D7;
      margin-bottom: 30px;
    }

    .cn-title {
      font-size: 56px;
      font-weight: bold;
      color: #1D1D1D;
      margin-bottom: 22px;
    }

    .desc {
      font-size: 18px;
      color: #1D1D1D;
      margin-bottom: 50px;
    }

    .list {
      width: 100%;
      max-width: 1400px;
      display: flex;
      flex-wrap: wrap;

      .item {
        width: 33.3%;
        flex: 0 0 33.3%;
        padding: 15px;

        .content {
          height: 183px;
          display: flex;
          justify-content: center;
          align-items: center;
          background: #FFFFFF;
          padding: 27px 74px;
          box-sizing: border-box;
          cursor: pointer;
          transition: box-shadow .5s ease-in-out;

          &:hover {
            box-shadow: 2px 3px 3px 0px rgba(8, 1, 3, 0.1);
          }

          .icon {
            width: 50px;
            height: 50px;
            margin-right: 24px;
          }

          .intro {
            flex: 1;

            .title {
              font-size: 24px;
              font-weight: bold;
              color: #191717;
              margin-bottom: 20px;
            }

            .text {
              line-height: 24px;
              font-size: 18px;
              color: #191717;
            }
          }
        }

      }
    }
  }

  .application-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 55px 0;
    background: #F6F6F6;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    .en-title {
      font-size: 24px;
      color: #0E80D7;
      margin-bottom: 30px;
    }

    .cn-title {
      font-size: 56px;
      font-weight: bold;
      color: #fff;
      margin-bottom: 22px;
    }

    .desc {
      font-size: 18px;
      color: #fff;
      margin-bottom: 50px;
    }

    .list {
      width: 100%;
      max-width: 1400px;
      display: flex;

      .item+.item {
        margin-left: 17px;
      }

      .item {
        flex: 16.66%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        padding: 86px 0 36px;
        background-repeat: no-repeat;
        background-size: 100%;
        background-position: center;
        box-sizing: border-box;
        cursor: pointer;
        transition: background-size .3s ease-in-out;

        &:hover {
          background-size: 120%;
        }

        .text {
          font-size: 30px;
          color: #FFFFFF;
          margin-top: 166px;
        }
      }
    }
  }

  .test-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 55px 0;
    background: #fff;

    .en-title {
      font-size: 24px;
      color: #0E80D7;
      margin-bottom: 30px;
    }

    .cn-title {
      font-size: 56px;
      font-weight: bold;
      color: #1D1D1D;
      margin-bottom: 22px;
    }

    .desc {
      font-size: 18px;
      color: #2B2B2B;
      margin-bottom: 50px;
    }

    .list {
      width: 100%;
      max-width: 1400px;
      display: flex;
      flex-wrap: wrap;

      .item {
        flex: 0 0 25%;
        padding: 15px;

        .content {
          display: flex;
          flex-direction: column;
          align-items: center;

          .icon {
            cursor: pointer;
            transition: transform .5s ease-in-out;

            &:hover {
              transform: scale(1.1);
            }
          }

          .text {
            font-size: 18px;
            font-weight: bold;
            color: #1D1D1D;
            margin-top: 20px;
          }
        }
      }
    }
  }

  .cert-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 55px 0;
    background: #F6F6F6;

    .en-title {
      font-size: 24px;
      color: #0E80D7;
      margin-bottom: 30px;
    }

    .cn-title {
      font-size: 56px;
      font-weight: bold;
      color: #1D1D1D;
      margin-bottom: 22px;
    }

    .desc {
      font-size: 18px;
      color: #2B2B2B;
      margin-bottom: 50px;
    }

    .list {
      width: 100%;
      max-width: 1400px;
      display: flex;
      justify-content: center;
      flex-wrap: wrap;

      .item {
        flex: 0 0 50%;
        padding: 15px;
        display: flex;
        flex-direction: column;
        align-items: center;

        &:nth-child(n+2) {
          margin-top: 10px;
        }

        .text {
          font-size: 30px;
          color: #065292;
          margin-bottom: 15px;

          .special {
            color: #E71F19;
          }
        }

        .imgs {
          display: flex;

          .ant-image+.ant-image {
            margin-left: -2px;
          }

          .ant-image {
            width: 160px;
            height: 100%;
            border: 2px solid #065292;
          }
        }
      }
    }
  }
}