.home-page-container {
  .video-content {
    margin: 0 auto;
    padding: 64px 0 100px 0;
    text-align: center;
    position: relative;

    .item {
      width: 1000px !important;
      position: relative;

      video {
        width: 1000px;
        display: block;
        margin: auto;
      }

      .poster-title {
        font-size: 24px;
        color: #1C1C1C;
        margin-top: 20px;
      }

      .left-icon {
        width: 50px;
        height: 130px;
        position: absolute;
        top: 50%;
        left: -100px;
        z-index: 99;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #e7e7e7;
        transform: translateY(-50%);
        cursor: pointer;
      }

      .right-icon {
        width: 50px;
        height: 130px;
        position: absolute;
        top: 50%;
        right: -100px;
        z-index: 99;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #e7e7e7;
        transform: translateY(-50%);
        cursor: pointer;
      }
    }
  }

  .about-content {
    padding: 72px 0;
    margin-bottom: 100px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    .square {
      width: 1200px;
      height: 369px;
      padding: 0 60px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 0 auto;
      background: rgba(14, 128, 215, 0.8);

      .intro {

        h3 {
          font-size: 36px;
          font-weight: bold;
          color: #FFFFFF;
        }

        .intro-text {
          line-height: 30px;
          font-size: 18px;
          color: #FFFFFF;
          margin: 30px 0 20px 0;
        }
      }

      .right-num {
        width: 606px;
        height: 291px;
        display: flex;
        flex: 0 0 606px;
        flex-wrap: wrap;
        border: 1px solid #FFFFFF;
        margin-left: 54px;

        .item {
          height: 145px;
          display: flex;
          justify-content: center;
          align-items: center;
          flex: 0 0 50%;

          &:nth-child(2n+1) {
            border-right: 1px solid #FFFFFF;
          }

          &:nth-child(n+3) {
            border-top: 1px solid #FFFFFF;
          }

          .text1 {
            font-size: 48px;
            font-weight: 500;
            color: #FF9000;
            margin-right: 12px;
          }

          .text2 {
            font-size: 24px;
            font-weight: 500;
            color: #FFFFFF;
          }
        }
      }
    }
  }

  .news-content {
    width: 1200px;
    text-align: center;
    margin: 0 auto 190px;

    .news-title-en {
      font-size: 24px;
      color: #0E80D7;
      margin-bottom: 30px;
    }

    .news-title-cn {
      font-size: 56px;
      font-weight: bold;
      color: #1D1D1D;
      margin-bottom: 70px;
    }

    .list-content {
      display: flex;
      flex-direction: row;
      align-items: stretch;

      .left-content {
        width: 518px;
        display: flex;
        flex-direction: column;
        margin-right: 32px;
        transition: all 0.4s;
        cursor: pointer;

        &:hover {
          box-shadow: 0px 1px 10px 1px rgba(8, 1, 3, 0.2);
        }

        img {
          width: 518px;
          height: 350px;
        }

        .info-content {
          display: flex;
          align-items: center;
          flex: 1;
          padding: 20px;
          text-align: left;
          background-color: #fff;

          .date {
            width: 70px;
            height: 70px;
            display: flex;
            flex: 0 0 70px;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            font-size: 14px;
            color: #FFFFFF;
            background-color: #0E80D7;
            margin-right: 14px;

            .day {
              font-size: 36px;
              font-weight: bold;
              color: #FFFFFF;
              margin-bottom: 8px;
            }
          }

          .title {
            font-size: 16px;
            font-weight: 500;
            color: #666666;
            margin-bottom: 8px;
          }

          .desc {
            line-height: 24px;
            font-size: 14px;
            font-weight: 500;
            color: #808080;
            margin: 0;
          }
        }
      }

    }

    .right-content {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-around;

      .right-item {
        display: flex;
        text-align: left;
        padding: 16px;
        transition: all 0.4s;
        border-bottom: 1px dashed #D9D9D9;
        cursor: pointer;

        &:hover {
          box-shadow: 0px 1px 10px 1px rgba(8, 1, 3, 0.2);
        }

        img {
          width: 126px;
          height: 88px;
          margin-right: 16px;
        }

        .info-content {
          display: flex;
          flex-direction: column;
          justify-content: center;

          .title {
            font-size: 16px;
            font-weight: 500;
            color: #666666;
          }

          .date {
            font-size: 12px;
            font-weight: 500;
            color: #CCCCCC;
            margin-top: 2px;
            margin-bottom: 8px;
          }

          .desc {
            line-height: 24px;
            font-size: 14px;
            font-weight: 500;
            color: #808080;
            margin: 0;
          }
        }
      }
    }
  }
}