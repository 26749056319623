@import './ratio';
@import './ellipsis';
@import './mixin';
@import './flex-class';
@import './theme'; // antd.css
@import 'reset.css'; // 覆盖antd的html基本样式

body {
  width: 100%;
  overflow-x: hidden
}

// ellipsis
.ellipsis {
  @include ellipsis();
}

.ellipsis-2 {
  @include ellipsis(2);
}

.ellipsis-3 {
  @include ellipsis(3);
}

.ellipsis-4 {
  @include ellipsis(4);
}

// ratio
.ratio-16-9 {
  @include ratio(56.25%);
}

.ratio-5-4 {
  @include ratio(80%);
}

.ratio-1-1 {
  @include ratio(100%);
}

// border-line
.border-line {
  @include borderLine(2rpx, #ccc, 40rpx)
}

// hidden
.hidden {
  visibility: hidden;
  opacity: 0;
  position: absolute;
}

// 路由切换动画
.animate-enter {
  opacity: 0.1;
  transform: translateY(20px);
}

.animate-enter-active {
  opacity: 1;
  transform: translateY(0px);
  transition: opacity 200ms, transform 200ms;
}

.animate-exit {
  opacity: 1;
  transform: translateY(0px);
}

.animate-exit-active {
  opacity: 0.1;
  transform: translateY(50px);
  transition: opacity 500ms, transform 500ms;
}