.ant-layout-header.site-header {
  width: 100%;
  height: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
  transition: all 0.4s;

  &.fixed-active {
    background: #fff;

    .max-content {
      .right-content {
        .menu-item {
          color: #000;
        }
      }
    }
  }

  .max-content {
    width: 100%;
    max-width: 1400px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .left-content {
      width: 270px;
      height: 60px;
      cursor: pointer;

      .placeholder {
        width: 270px;
        height: 60px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    .right-content {
      display: flex;

      .menu-item+.menu-item {
        margin-left: 40px;
      }

      .menu-item {
        font-size: 16px;
        font-weight: bold;
        cursor: pointer;
        color: #fff;
        border-bottom: 2px solid transparent;
        user-select: none;

        &.active,
        &:hover {
          color: #0E80D7;
          border-bottom: 2px solid #0E80D7;
          transition: all .5s ease-in-out;
        }
      }
    }
  }
}