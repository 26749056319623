@mixin borderLine ($borderWidth: 2rpx, $borderColor: #888, $borderSpace: 10rpx) {
  & {
    position: relative;

    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: $borderWidth;
      background-image: linear-gradient(to right, $borderColor 0%, $borderColor 50%, transparent 0%);
      background-size: $borderSpace 1px;
      background-repeat: repeat-x;
    }
  }
}