@mixin ellipsis ($lines: 1, $max-height: initial) {
  // Fallback for non-webkit browsers.
  // Fallback does not render ellipsis.
  overflow: hidden;
  text-overflow: ellipsis;
  max-height: $max-height;
  display: flex;

  // Webkit solution for multiline ellipsis
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: $lines;

  // Solution for Opera
  text-overflow: -o-ellipsis-lastline;
}
